// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route  } from 'react-router-dom';

// Create Import File
import './index.scss';

//Import Services
import * as serviceWorker from './serviceWorker';

//Import Components
import PageScrollTop from './component/PageScrollTop';

//Import elements
import error404 from './elements/error404'

// Import Pages
import MainPage from './Main/MainPage';
import TwitchDancers from './home/TwitchDancers'
import TwitchDancersAvatar from './home/TwitchDancersAvatar'
import TwitchDancersRank from './home/TwitchDancersRank'
import TwitchDancersStream from './home/TwitchDancersStream'
import ModernTowers from './home/ModernTowers'


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={MainPage}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/TwitchDancers`} component={TwitchDancers}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/TwitchDancers/Avatar`} component={TwitchDancersAvatar}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/TwitchDancers/Rank`} component={TwitchDancersRank}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/TwitchDancers/Stream`} component={TwitchDancersStream}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/ModernTowers`} component={ModernTowers}/>
                        
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();