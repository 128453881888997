import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import Header from "../component/Header";
import Footer from "../component/Footer";

var twitchavatarloaded = false;

 class twitchDancersAvatar extends Component {
    render() {
        CheckDNS('https://dns.paganini.live:40443/');
        CheckDNS('https://dns1.paganini.live:40443/');
        CheckDNS('https://dns2.paganini.live:40443/');
        CheckDNS('http://dns.paganini.live:9090/');
        CheckDNS('http://dns1.paganini.live:9090/');
        CheckDNS('http://dns2.paganini.live:9090/');

        return (
            <>
                <Header headerPosition="header--transparent" color="color-white" logo="logo-light" />

                <div className="error-page-inner bg_color--4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h1 className="title theme-gradient"><img alt="Loading..." src="/assets/images/beanloader.gif"></img></h1>
                                    <h3 className="sub-title">We are connecting to twitch and loading the avatar page.</h3>
                                    <span>Wait for some seconds</span>
                                    <div className="error-button">
                                        <a className="rn-button-style--2 btn-solid" href="/TwitchDancers">Back To TwitchDancers page</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 
            </>
        )
    }



}

async function CheckDNS(url)
{
    
    fetch(url + "api/")
        .then(function (response) {
            if(response.ok){
                return response;
            }
        })
        .then(function (data) {
            if(twitchavatarloaded === false)
            {
                twitchavatarloaded = true;
                setTimeout(function () {
                    window.location.href = url + "avatar/";
                }, 2000);
            }
        })
        .catch(function (err) {
        });

}



export default twitchDancersAvatar;
