import React from 'react'
const TDDownloadUrl = "https://github.com/fpaganini/TwitchDancers/releases/download/0.7.4/TwitchDancersSetup.exe";
const TwitchDancersDownload = () => {
    return (
        <div className="call-to-action-wrapper call-to-action2 text-white-wrapper  ptb--120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <span>READY TO STREAM?</span>
                            <h2>Let's Dance</h2>
                            <a key={74} id='lnk_074' className="rn-button-style--2" href={TDDownloadUrl}><span>Download</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TwitchDancersDownload;