import React, {Component, Fragment} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Link} from 'react-scroll'

import Footer from "../component/Footer";
import 'react-accessible-accordion/dist/fancy-example.css';

const allGames = [
    {
        url: 'TwitchDancers',
        imageUrl: 'twitchdancerscover.png',
        title: 'Twitch Dancers',
        description: 'On Twitch Dancers you get in on the dance floor and animate the show with your incredible movements.',
        label: 'New'
    },
    {
        url: 'ModernTowers',
        imageUrl: 'moderntowerscover.png',
        title: 'Modern Towers',
        description: 'In Modern Towers you play as Harold, Karoline and Theo for defend Krazo planet, and you receive your followers help with this mission.',
        label: 'Early Access'
    },
]

const twitchIntegrationGames = [
    {
        url: 'TwitchDancers',
        imageUrl: 'twitchdancerscover.png',
        title: 'Twitch Dancers',
        description: 'On Twitch Dancers you get in on the dance floor and animate the show with your incredible movements.',
        label: 'New'
    },
    {
        url: 'ModernTowers',
        imageUrl: 'moderntowerscover.png',
        title: 'Modern Towers',
        description: 'In Modern Towers you play as Harold, Karoline and Theo for defend Krazo planet, and you receive your followers help with this mission.',
        label: 'Early Access'
    },
]

const streamOverlayGames = [
    {
        url: 'TwitchDancers',
        imageUrl: 'twitchdancerscover.png',
        title: 'Twitch Dancers',
        description: 'On Twitch Dancers you get in on the dance floor and animate the show with your incredible movements.',
        label: 'New'
    },
]

class MainPage extends Component {
    render() {
        return (
            <Fragment>
                {/* Start Banner Area  */}
                <div className="prv-banner-wrapper"
                     style={{backgroundImage: `url("assets/images/bg/smalbg.jpg")`}}>
                    <div className="container-fluid">
                        <div className="plr--120">
                            <div className="row">
                                <div className="col-lg-8 col-xl-5">
                                    <div className="inner">
                                        <div className="logo text-left">
                                            <a href="/">
                                                <img src="/assets/images/logo.png" alt="PaganiniGameStudio"/>
                                            </a>
                                        </div>
                                        <p className="title">Our passion are innovate the way that you interact with your favorite streamer <span className="subtitle">Cause watch is also another way for enjoy games</span></p>
                                        
                                        <div className="purshase-btn">
                                            <a href="https://store.steampowered.com/developer/paganinigamestudio" className="rn-button-style--2 btn-solid steambutton">
                                                <img src="/assets/images/steamlogo.png" alt="Steam logo"/>
                                                Steam</a>
                                                <Link to="ourgames" smooth="true" className="rn-button-style--2 btn-border rn-btn-dark border-opacity-half">Our Games</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fullscreen empty-div gradient-overlay"></div>
                {/* End Banner Area  */}

                {/* Start Preview Main Wrapper */}
                <div className="main-wrapper" id="ourgames">

                    {/* Start Main Demo Area  */}
                    <div className="home-demo-area bg_color--1 ptb--120">
                        <div className="wrapper plr--120">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center pb--30">
                                        <h2 className="theme-gradient" id="OurGamesName">Our Games</h2>
                                        <p>Se some of our improgress games</p>
                                    </div>
                                </div>
                            </div>
                            <Tabs>
                                <div className="row text-center">
                                    <div className="col-lg-12">
                                        <div className="tablist-inner">
                                            <TabList className="pv-tab-button text-center">
                                                <Tab><span>All Games</span></Tab>
                                                <Tab><span>Twitch Integration</span></Tab>
                                                <Tab><span>Stream Overlay</span></Tab>
                                            </TabList>
                                        </div>
                                    </div>
                                </div>
                                
                                <TabPanel className="row">
                                    {allGames.map((value , index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a rel="noopener noreferrer" href={`/${value.url} `}>
                                                    <img src={`/assets/images/games/${value.imageUrl}`} alt="Paganini Game Studio Cover"/>
                                                    <h3 className="title">{value.title}</h3>
                                                    <p className="info">{value.description}</p>
                                                    {value.label ? <div className="label-new"><span>{value.label}</span></div> : ''}
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel>

                                <TabPanel className="row">
                                    {twitchIntegrationGames.map((value , index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a rel="noopener noreferrer" href={`/${value.url} `}>
                                                    <img src={`/assets/images/games/${value.imageUrl}`} alt="Paganini Game Studio Cover"/>
                                                    <h3 className="title">{value.title}</h3>
                                                    <p className="info">{value.description}</p>
                                                    {value.label ? <div className="label-new"><span>{value.label}</span></div> : ''}
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel>

                                <TabPanel className="row">
                                    {streamOverlayGames.map((value , index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a rel="noopener noreferrer" href={`/${value.url} `}>
                                                    <img src={`/assets/images/games/${value.imageUrl}`} alt="Paganini Game Studio Cover"/>
                                                    <h3 className="title">{value.title}</h3>
                                                    <p className="info">{value.description}</p>
                                                    {value.label ? <div className="label-new"><span>{value.label}</span></div> : ''}
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel>

                            </Tabs>

                        </div>
                    </div>
                    {/* End Main Demo Area  */}

                    <Footer />     

   
                </div>
                {/* End Preview Main Wrapper */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default MainPage;