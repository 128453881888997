import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import Header from "../component/Header";
import Footer from "../component/Footer";

import TwitchDancersDownload from "../elements/TwitchDancersDownload"
import TwitchDancersInfo from "../elements/TwitchServices";
import TwitchDancersSupport from "../elements/TwitchSupport";

class TwitchDancersStream extends Component{
    render(){
        return(
            <div className="active-dark"> 
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                <div className="rn-blog-area pt--120 pb--80 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3">
                                    <h2><a href="/TwitchDancers"><span className="TDPurple">Twitch</span> <span className="TDPink">Dancers</span></a> <span className="TDPurple">| Stream</span></h2>
                                    <p>If you are a twitch streamer, use an OBS, StreamLabs or similar software for stream, you can use TwitchDancers now for free.</p>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className="service-wrapper service-white">
                            <TwitchDancersInfo/>
                        </div>
                        
                    </div>    
                </div>

                <TwitchDancersDownload/>

                <div className="rn-blog-area pt--120 pb--80 bg_color--1">
                    <div className="container">
                        <TwitchDancersSupport/>
                    </div>
                </div>

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </div>
        )
    }
}
export default TwitchDancersStream;