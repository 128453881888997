import React, { Component } from "react";
import { MdLocalLibrary } from "react-icons/md";
import { AiOutlineApi } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";

const ServiceList = [
    {
        icon: <MdLocalLibrary />,
        title: 'Docs',
        description: 'Access our official documentantion with FAQs and Manuals for TwitchDancers instalation and configuration.',
        url: 'https://paganini.live/assets/quick_reference.pdf'
    },
    {
        icon: <FaDiscord />,
        title: 'Community Support',
        description: 'Get int on our official Discord Server for a community support and experience exchange.',
        url: 'https://discord.gg/mNZSV5SH9c'
    },
    {
        icon: <AiOutlineApi />,
        title: 'API',
        description: 'If you are an developer or kind off, maybe you want to know our open API.',
        url: 'https://dns1.paganini.live:40443/api/swagger/index.html'
    }
]

class TwitchSupport extends Component{
    render(){
        let title = 'Support',
        description = 'See in this section how to configure twitch dancers, access to our communit and more.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.url}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default TwitchSupport;
