import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import Header from "../component/Header";
import Footer from "../component/Footer";

import CounterOne from "../elements/TwitchDancersCounter";
import Portfolio from "../component/TwitchDancersStreamers";

const ActionList = [
    {
        image: '/assets/images/twitchdancers/createavatar.png',
        category: 'Personalize',
        title: 'Create your Avatar',
        url: '/TwitchDancers/Avatar',
        action: 'Create Now'
    },
    {
        image: '/assets/images/twitchdancers/ranking.png',
        category: 'Compete',
        title: 'Ranking',
        url: '/TwitchDancers/Rank',
        action: 'Check it now'
    },
    {
        image: '/assets/images/twitchdancers/streaming.png',
        category: 'Streaming',
        title: 'Stream Twitch Dancers',
        url: '/TwitchDancers/Stream',
        action: 'Streamer Zone'
    },
]

class MainDemo extends Component{
    render(){
        return(
            <div className="active-dark"> 
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                <div className="rn-blog-area pt--120 pb--80 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3">
                                    <h2><span className="TDPurple">Twitch</span> <span className="TDPink">Dancers</span></h2>
                                    <p>There are many ways to dance. Choose yours.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60">
                            {
                                ActionList.map((value , index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                        <div className="blog blog-style--1 text-center">
                                            <div className="thumbnail">
                                                <a href={value.url}>
                                                    <img className="w-100" src={value.image} alt={value.title}/>
                                                </a>
                                            </div>
                                            <a href={value.url}>
                                                <div className="content">
                                                        <p className="blogtype">{value.category}</p>
                                                        <h4 className="title"><a href={value.url}>{value.title}</a></h4>
                                                        <div className="blog-btn">
                                                            <a className="rn-btn text-white" href={value.url}>{value.action}</a>
                                                        </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                            ))}
                        </div>
                    </div>    
                </div>
                
                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500"><span className="TDPurple">The</span> <span className="TDPink">numbers</span></h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </div>
        )
    }
}
export default MainDemo;