import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import Header from "../component/Header";
import Footer from "../component/Footer";

var twitchrankloaded = false;
var twitchrankurl = "";

class TwitchDancersRank extends Component{

    constructor(props) {
        super(props);
        this.state = { streamerList: [], isLoaded: false };
        
        CheckDNS('https://dns.paganini.live:40443/',this);
        CheckDNS('https://dns1.paganini.live:40443/',this);
        CheckDNS('https://dns2.paganini.live:40443/',this);
        CheckDNS('http://dns.paganini.live:9090/',this);
        CheckDNS('http://dns1.paganini.live:9090/',this);
        CheckDNS('http://dns2.paganini.live:9090/',this);
    
      }

      UpdateData(){
        if(twitchrankloaded && twitchrankurl !== "")
        {
            fetch(twitchrankurl)
            .then(res => res.json())
            .then(result => {
                this.setState({
                isLoaded: true,
                streamerList: result
                });
            });
        };
      }


      componentDidMount() {
        this.UpdateData();
      }

    render(){

        
        return(
            <div className="active-dark"> 
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                <div className="rn-blog-area pt--120 pb--80 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3">
                                    <h2><a href="/TwitchDancers"><span className="TDPurple">Twitch</span> <span className="TDPink">Dancers</span></a> <span className="TDPurple">| RANK</span></h2>
                                    <p>See the best ranked Streamers. Maybe you have a crown on some of then.</p>
                                </div>
                            </div>
                        </div>
                        
                            
                            {
                                (this.state.streamerList.length  === 0) &&
                                <div className="error-page-inner bg_color--4">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="inner">
                                                    <h1 className="title theme-gradient"><img src="/assets/images/beanloader.gif" alt="Loading..."></img></h1>
                                                    <h3 className="sub-title">We are loading top streamers.</h3>
                                                    <span>Wait for some seconds</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            
                            <div className="row mt--60">

                                {this.state.streamerList.map((value , index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                        <div className="blog blog-style--1 text-center">
                                            <div className="thumbnail streamerrankcontainer">
                                                <a href={'https://twitch.tv/' + value.channelName}>
                                                    <img className="streamerrankbg" src={'https://static-cdn.jtvnw.net/jtv_user_pictures/' + value.channelBanner} alt="Blog Images"/>
                                                
                                                    <div className="streamerrankpoints">
                                                        {
                                                            value.players.slice(0,10).map((player , index) => (
                                                            <div className="streamerplayerline">
                                                                <img alt='Logo' src={'https://static-cdn.jtvnw.net/jtv_user_pictures/' + player.playerLogo.replace('300x300','50x50')}></img>
                                                                <span className="placarLeft"><span className="playerName">{player.playerName}</span></span>
                                                                <span className="placarRight ">{player.playerScore}</span>
                                                            </div>
                                                            
                                                        ))}
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="content">
                                                <h4 className="title"><a href={'https://twitch.tv/' + value.channelName}><span className="">{value.channelName}</span></a></h4>
                                                <div className="blog-btn">
                                                    <a className="rn-btn text-white" href={'https://twitch.tv/' + value.channelName}>Watch now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            
                            </div>
                            
                    </div>    
                </div>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </div>
        )
    }
}



async function CheckDNS(url,parent)
{
    fetch(url + "api/")
        .then(function (response) {
            if(response.ok){
                return response;
            }
        })
        .then(function (data) {
            if(twitchrankloaded === false)
            {
                twitchrankloaded = true;
                twitchrankurl = url + 'api/TwitchDancers/Score/Last30Days';
                parent.UpdateData();
            }
        })
        .catch(function (err) {
        });

}


export default TwitchDancersRank;