import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../Main/script";


const StreamerList = [
    {
        avatar: 'https://static-cdn.jtvnw.net/jtv_user_pictures/9459da0c-4227-454f-b7a9-c85056c7943e-profile_image-300x300.png',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/26c6c006-d064-4b14-9c86-0f9797a4c3ba-profile_banner-480.png',
        category: '',
        title: 'golimarlive',
        url: 'https://twitch.tv/golimarlive'
    },
    {
        avatar: 'https://static-cdn.jtvnw.net/jtv_user_pictures/934af91c-dc2d-45fb-9ef7-e2bbf5c26b6c-profile_image-300x300.png',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/34b5cc3a-e38a-4584-a5d6-b029ed4917d0-profile_banner-480.png',
        category: '',
        title: 'testingames',
        url: 'https://twitch.tv/testingames'
    },
    {
        avatar: 'https://static-cdn.jtvnw.net/jtv_user_pictures/9c1f81ab-e8d7-4fed-a524-bbe63735b7a7-profile_image-300x300.png',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/2a433a46-592c-4f1e-943d-4f74d5fc5585-profile_banner-480.jpeg',
        category: '',
        title: 'ayalagk',
        url: 'https://twitch.tv/ayalagk'
    },
    {
        avatar: 'https://static-cdn.jtvnw.net/jtv_user_pictures/1ef3425c-8429-4480-8ae4-897aa6e83064-profile_image-300x300.png',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/b51d9efd-fa94-4dfd-ad84-7914f44f88b4-profile_banner-480.png',
        category: '',
        title: 'cmoonlightc',
        url: 'https://twitch.tv/cmoonlightc'
    },
    {
        avatar: 'https://static-cdn.jtvnw.net/jtv_user_pictures/6c5c46be-074f-4915-83ad-491d96422ea1-profile_image-300x300.png',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/00656d42-0be0-4b2d-8f47-903a5d8ca624-profile_banner-480.png',
        category: '',
        title: 'dengve',
        url: 'https://twitch.tv/dengve'
    },
    {
        avatar: 'https://static-cdn.jtvnw.net/jtv_user_pictures/ec9671af-566a-4b10-af63-f8e82ad6b977-profile_image-300x300.png',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/aa60a508-a85c-4660-bd4c-f66ce321799a-profile_banner-480.jpeg',
        category: '',
        title: 'solteipum_',
        url: 'https://twitch.tv/dengve'
    },
    {
        avatar: 'https://static-cdn.jtvnw.net/jtv_user_pictures/013fd993-cb03-40d3-a235-ddcbebc5910f-profile_image-300x300.png',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/bc8dbd84-5886-4d0e-b5db-a503f9b186ca-profile_banner-480.png',
        category: '',
        title: 'paganinigamestudio',
        url: 'https://twitch.tv/paganinigamestudio'
    }
]

class TwitchDancersStreamers extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2><span className="TDPurple">Our</span> <span className="TDPink">Streamers</span></h2>
                                    <p>There are some of awesome streamers that use TwitchDancers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {StreamerList.map((value , index) => (
                                <div className="portfolio portfolio-twitchdancers" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail`} style={{"background": "url(" + value.image +")"}}>
                                        <div className="twitchAvatar">
                                            <img alt='Avatar' src={value.avatar}></img>
                                        </div>
                                        </div>
                                        <div className={`bg-blr-image`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4 className="title"><a href={value.url}>{value.title}</a></h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href={value.url}>See this channel</a>
                                            </div>
                                        </div>
                                    </div>
                                    <a href={value.url} className="link-overlay"><span></span></a>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default TwitchDancersStreamers;