import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Stream Overlay',
        description: 'Capture TwitchDancers as an overlay on your OBS or other Broadcast software and let your audience dance when you play your favorite game.'
    },
    {
        icon: '02',
        title: 'Community engagement',
        description: 'Create challenges and encourage the competition among your audience, earn points and get the first place on Streamer Rank'
    },
    {
        icon: '03',
        title: 'Customize',
        description: 'Accumulate points and then spend it all on customizations, acquiring new songs and dance moves.'
    },
]

class TwitchServices extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default TwitchServices;